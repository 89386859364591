/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import TextArea from '../../framework-components/textarea';
import Radio from '../../framework-components/select/radio';
import { Option } from '../../framework-components/select/types';
import Loading from '../../framework-components/loading';
import WrappedField from '../forms/wrapped-field';
import Checkbox from '../../framework-components/select/checkbox';
import FileUpload from '../../framework-components/file-upload';
import {
  Section3part1,
  Section3part2,
  Section3part3,
  Section3part4,
  Section3part6,
  TOOLTIP_STYLE,
} from './guidance';
import InfoIcon from '../assets/InfoIcon';

interface Values {
  building_construction: {
    year_of_construction: string;
    type_of_construction: string;
    type_of_construction_other_details: string;
    dwelling_type: string;
    additional_notes: string;
  };
  floor_area: {
    total_internal_floor_area: string;
    number_of_bedrooms: string;
  };
  existing_retrofit_improvements: {
    external_wall_insulation: boolean;
    cavity_filled_insulation: boolean;
    double_glazed_windows: boolean;
    double_glazed_windows_percentage: number;
    loft_insulation: boolean;
    loft_insulation_approx_thickness: number;
    loft_hatch_insulation: boolean;
    floor_insulation: boolean;
  };
  location_of_adjoining_properties: {
    above: boolean;
    below: boolean;
    left: boolean;
    right: boolean;
    none: boolean;
  };
}

const year_of_construction_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Pre 1919'),
    value: 'PRE_NINETEEN_NINETEEN',
  },
  {
    label: i18next.t('1920 - 1944'),
    value: 'NINETEEN_TWENTY_NINETEEN_FORTY_FOUR',
  },
  {
    label: i18next.t('1945 - 1964'),
    value: 'NINETEEN_FOURTY_FIVE_NINETEEN_SIXTY_FOUR',
  },
  {
    label: i18next.t('1965 - 1977'),
    value: 'NINETEEN_SIXTY_FIVE_NINETEEN_SEVENTY_SEVEN',
  },
  {
    label: i18next.t('1978 - 1984'),
    value: 'NINETEEN_SEVENTY_EIGHT_NINETEEN_EIGHTY_FOUR',
  },

  {
    label: i18next.t('1985 - 1994'),
    value: 'NINETEEN_EIGHTY_FOUR_NINETEEN_NINETY_FOUR',
  },

  {
    label: i18next.t('1994 - 2006'),
    value: 'NINETEEN_NINETY_FOUR_TWO_THOUSAND_SIX',
  },
  {
    label: i18next.t('After 2006'),
    value: 'AFTER_TWO_THOUSAND_SIX',
  },
];

const type_of_construction_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Steel frame (low thermal mass)'),
    value: 'STEEL_FRAME',
  },
  {
    label: i18next.t('Timber frame (low thermal mass)'),
    value: 'TIMBER_FRAME',
  },
  {
    label: i18next.t('Cavity wall (medium thermal mass)'),
    value: 'CAVITY_WALL',
  },
  {
    label: i18next.t('Solid wall (high thermal mass)'),
    value: 'SOLID_WALL',
  },
  {
    label: i18next.t('Modern method of construction'),
    value: 'MODERN_METHOD_OF_CONSTRUCTION',
  },
  {
    label: i18next.t('Other - Specify'),
    value: 'OTHER',
  },
];

const dwelling_type_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('1 storey house / bungalow'),
    value: 'ONE_STOREY_HOUSE',
  },
  {
    label: i18next.t('2 storey house'),
    value: 'TWO_STOREY_HOUSE',
  },
  {
    label: i18next.t('3+ storey house'),
    value: 'THREE_PLUS_STOREY_HOUSE',
  },
  {
    label: i18next.t('Purpose built flat'),
    value: 'PURPOSE_BUILT_FLAT',
  },
  {
    label: i18next.t('Converted flat'),
    value: 'CONVERTED_FLAT',
  },
];

const existing_retrofit_improvements_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('External wall insulation'),
    value: 'existing_retrofit_improvements.external_wall_insulation',
  },
  {
    label: i18next.t('Cavity filled insulation'),
    value: 'existing_retrofit_improvements.cavity_filled_insulation',
  },
  {
    label: i18next.t('Double glazed windows'),
    value: 'existing_retrofit_improvements.double_glazed_windows',
  },
  {
    label: i18next.t('Loft insulation'),
    value: 'existing_retrofit_improvements.loft_insulation',
  },
  {
    label: i18next.t('Loft hatch insulation'),
    value: 'existing_retrofit_improvements.loft_hatch_insulation',
  },
  {
    label: i18next.t('Floor insulation'),
    value: 'existing_retrofit_improvements.floor_insulation',
  },
];

const location_of_adjoining_properties_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Above'),
    value: 'location_of_adjoining_properties.above',
  },
  {
    label: i18next.t('Below'),
    value: 'location_of_adjoining_properties.below',
  },
  {
    label: i18next.t('Left'),
    value: 'location_of_adjoining_properties.left',
  },
  {
    label: i18next.t('Right'),
    value: 'location_of_adjoining_properties.right',
  },
  {
    label: i18next.t('None'),
    value: 'location_of_adjoining_properties.none',
  },
];

const BuildingConstruction: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/building_construction/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/building_construction/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);
        if (hasSucceeded) {
          mutate();
          navigate(`/ventilation?survey_id=${currentSurveyID?.survey_id}`);
        }
      },
      [navigate, submitForm]
    );
  }

  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>{i18next.t('3. Building construction')}</div>
          </h1>
          <Formik<Values>
            enableReinitialize
            initialValues={{
              ...data,
              existing_retrofit_improvements: {
                external_wall_insulation: Boolean(
                  data.existing_retrofit_improvements.external_wall_insulation
                ),
                cavity_filled_insulation: Boolean(
                  data.existing_retrofit_improvements.cavity_filled_insulation
                ),
                double_glazed_windows: Boolean(
                  data.existing_retrofit_improvements.double_glazed_windows
                ),
                loft_insulation: Boolean(
                  data.existing_retrofit_improvements.loft_insulation
                ),
                loft_hatch_insulation: Boolean(
                  data.existing_retrofit_improvements.loft_hatch_insulation
                ),
                floor_insulation: Boolean(
                  data.existing_retrofit_improvements.floor_insulation
                ),
                loft_insulation_approx_thickness:
                  data.existing_retrofit_improvements
                    .loft_insulation_approx_thickness || 0,
                double_glazed_windows_percentage:
                  data.existing_retrofit_improvements
                    .double_glazed_windows_percentage || 0,
              },
              location_of_adjoining_properties: {
                above: Boolean(data.location_of_adjoining_properties.above),
                below: Boolean(data.location_of_adjoining_properties.below),
                left: Boolean(data.location_of_adjoining_properties.left),
                right: Boolean(data.location_of_adjoining_properties.right),
                none: Boolean(data.location_of_adjoining_properties.none),
              },
            }}
            onSubmit={onSubmit}
          >
            {({ status, values, handleChange, setFieldValue, errors }) => (
              <Form className="space-y-8 ">
                <div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('3.1. Year of construction')}
                    <span className="pl-1" data-tooltip-id={'section3part1'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section3part1'}
                        variant="info"
                        openOnClick
                      >
                        <Section3part1 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="year_of_construction"
                    options={year_of_construction_options}
                    onChange={(value) =>
                      setFieldValue(
                        'building_construction.year_of_construction',
                        value
                      )
                    }
                    errors={errors.building_construction?.year_of_construction}
                    defaultChecked={
                      values &&
                      values.building_construction.year_of_construction
                    }
                  />
                  <div className="text-lg font-bold p-0 m-0 pb-1 pt-3">
                    {i18next.t('3.2 Floor area')}
                    <span className="pl-1" data-tooltip-id={'section3part2'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section3part2'}
                        variant="info"
                        openOnClick
                      >
                        <Section3part2 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <WrappedField
                    className="pb-2"
                    name="floor_area.total_internal_floor_area"
                    label={i18next.t(
                      'Total internal floor area (m²). If unknown enter 0.'
                    )}
                    errors={errors.floor_area?.total_internal_floor_area}
                    placeholder={i18next.t('Enter floor area')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="floor_area.number_of_bedrooms"
                    label={i18next.t('Number of bedrooms. If unknown enter 0.')}
                    errors={errors.floor_area?.number_of_bedrooms}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('3.3 Type of construction')}
                    <span className="pl-1" data-tooltip-id={'section3part3'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section3part3'}
                        variant="info"
                        openOnClick
                      >
                        <Section3part3 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="building_construction.type_of_construction"
                    options={type_of_construction_options}
                    onChange={(value) =>
                      setFieldValue(
                        'building_construction.type_of_construction',
                        value
                      )
                    }
                    errors={errors.building_construction?.type_of_construction}
                    defaultChecked={
                      values &&
                      values.building_construction.type_of_construction
                    }
                  />
                  <WrappedField
                    className="py-2"
                    name="building_construction.type_of_construction_other_details"
                    label={i18next.t('Other details')}
                    errors={
                      errors.building_construction
                        ?.type_of_construction_other_details
                    }
                    placeholder={i18next.t('Enter other details')}
                    defaultValue={''}
                    disabled={
                      values.building_construction.type_of_construction !==
                      'OTHER'
                    }
                  />
                  <div className="text-lg font-bold p-0 m-0  pt-3">
                    {i18next.t('3.4 Dwelling type')}
                    <span className="pl-1" data-tooltip-id={'section3part4'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section3part4'}
                        variant="info"
                        openOnClick
                      >
                        <Section3part4 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="building_construction.dwelling_type"
                    options={dwelling_type_options}
                    onChange={(value) =>
                      setFieldValue(
                        'building_construction.dwelling_type',
                        value
                      )
                    }
                    errors={errors.building_construction?.dwelling_type}
                    defaultChecked={
                      values && values.building_construction.dwelling_type
                    }
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('3.5 Existing retrofit improvements')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Please select all that apply and add notes below'
                      )}
                    </em>
                  </div>
                  <Checkbox
                    key={existing_retrofit_improvements_options[0].label.toString()}
                    option={existing_retrofit_improvements_options[0]}
                    checked={Boolean(
                      values.existing_retrofit_improvements
                        .external_wall_insulation
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={existing_retrofit_improvements_options[1].label.toString()}
                    option={existing_retrofit_improvements_options[1]}
                    checked={Boolean(
                      values.existing_retrofit_improvements
                        .cavity_filled_insulation
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={existing_retrofit_improvements_options[2].label.toString()}
                    option={existing_retrofit_improvements_options[2]}
                    checked={Boolean(
                      values.existing_retrofit_improvements
                        .double_glazed_windows
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <WrappedField
                    className="py-2"
                    name="existing_retrofit_improvements.double_glazed_windows_percentage"
                    label={i18next.t(
                      'Double glazed windows (percentage). If unknown enter 0.'
                    )}
                    errors={
                      errors.existing_retrofit_improvements
                        ?.double_glazed_windows_percentage
                    }
                    placeholder={i18next.t('Enter percentage')}
                    defaultValue={0}
                    disabled={
                      values.existing_retrofit_improvements
                        .double_glazed_windows
                        ? false
                        : true
                    }
                  />
                  <Checkbox
                    key={existing_retrofit_improvements_options[3].label.toString()}
                    option={existing_retrofit_improvements_options[3]}
                    checked={Boolean(
                      values.existing_retrofit_improvements.loft_insulation
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <WrappedField
                    className="py-2"
                    name="existing_retrofit_improvements.loft_insulation_approx_thickness"
                    label={i18next.t(
                      'Loft insulation approx. thickness (millimetres). If unknown enter 0.'
                    )}
                    errors={
                      errors.existing_retrofit_improvements
                        ?.loft_insulation_approx_thickness
                    }
                    placeholder={i18next.t('Enter approx thickness')}
                    defaultValue={0}
                    disabled={
                      values.existing_retrofit_improvements.loft_insulation
                        ? false
                        : true
                    }
                  />
                  <Checkbox
                    key={existing_retrofit_improvements_options[4].label.toString()}
                    option={existing_retrofit_improvements_options[4]}
                    checked={Boolean(
                      values.existing_retrofit_improvements
                        .loft_hatch_insulation
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={existing_retrofit_improvements_options[5].label.toString()}
                    option={existing_retrofit_improvements_options[5]}
                    checked={Boolean(
                      values.existing_retrofit_improvements.floor_insulation
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('3.6 Location of adjoining properties')}
                    <span className="pl-1" data-tooltip-id={'section3part6'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section3part6'}
                        variant="info"
                        openOnClick
                      >
                        <Section3part6 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select all that apply')}</em>
                  </div>
                  <Checkbox
                    key={location_of_adjoining_properties_options[2].label.toString()}
                    option={location_of_adjoining_properties_options[2]}
                    checked={Boolean(
                      values.location_of_adjoining_properties.left
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={location_of_adjoining_properties_options[3].label.toString()}
                    option={location_of_adjoining_properties_options[3]}
                    checked={Boolean(
                      values.location_of_adjoining_properties.right
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={location_of_adjoining_properties_options[0].label.toString()}
                    option={location_of_adjoining_properties_options[0]}
                    checked={Boolean(
                      values.location_of_adjoining_properties.above
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={location_of_adjoining_properties_options[1].label.toString()}
                    option={location_of_adjoining_properties_options[1]}
                    checked={Boolean(
                      values.location_of_adjoining_properties.below
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={location_of_adjoining_properties_options[4].label.toString()}
                    option={location_of_adjoining_properties_options[4]}
                    errors={errors.location_of_adjoining_properties?.none}
                    checked={Boolean(
                      values.location_of_adjoining_properties.none
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <div className="text-base font-normal p-0 m-0 pb-1 pt-3">
                    <em>
                      {i18next.t(
                        'Please draw a floor plan for each floor of the home. At the end of the tool you can take a photo and upload your drawing.'
                      )}
                    </em>
                  </div>
                  <TextArea
                    id="building_construction.additional_notes"
                    label={i18next.t('Additional notes')}
                    instructions={i18next.t('Presence and date of extensions')}
                    name="building_construction.additional_notes"
                    onChange={handleChange}
                    defaultValue={
                      values && values.building_construction.additional_notes
                    }
                    placeholder={i18next.t('Add notes')}
                  />
                  <FileUpload
                    instructions={i18next.t(
                      'Add photos of facades, windows, external doors, roof finish and loft hatch'
                    )}
                    fieldName={'construction'}
                    clientId={currentSurveyID?.survey_id || ''}
                    section="BUILDING_CONSTRUCTION"
                  />
                </div>
                <NavigationProgress
                  status={status}
                  path={`/building-location/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={30}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default BuildingConstruction;
