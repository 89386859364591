import React, { useCallback } from 'react';

import { Option } from './types';
import ErrorText from '../../components/error-text';

interface Props {
  options: ReadonlyArray<Option>;
  onChange: (value: string) => void;
  defaultChecked?: string;
  name?: string;
  errors?: string;
}

const Radio: React.FC<Props> = ({
  options,
  onChange,
  defaultChecked,
  name,
  errors,
}) => {
  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  return (
    <fieldset>
      <div className="space-y-3">
        {options.map((option) => (
          <div key={option.value} className="relative flex items-start">
            <div className="text-sm leading-6">
              <label className="text-sm font-medium text-gray-900">
                <input
                  id={option.value}
                  aria-describedby={`${option.value}-description`}
                  name={name || 'radio_group'}
                  type="radio"
                  defaultChecked={
                    defaultChecked ? option.value === defaultChecked : false
                  }
                  className="mr-3 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={changeHandler}
                  value={option.value}
                />
                {option.label}
              </label>
              <p
                id={`${option.value}-description`}
                className="ml-3 text-gray-500"
              >
                {option.description}
              </p>
            </div>
          </div>
        ))}
        {errors && <ErrorText>{errors}</ErrorText>}
      </div>
    </fieldset>
  );
};

export default Radio;
