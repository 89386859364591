/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import TextArea from '../../framework-components/textarea';
import Loading from '../../framework-components/loading';
import WrappedField from '../forms/wrapped-field';
import Radio from '../../framework-components/select/radio';
import FileUpload from '../../framework-components/file-upload';
import { Section6part1, TOOLTIP_STYLE } from './guidance';
import InfoIcon from '../assets/InfoIcon';

interface Values {
  appliances_and_lighting: {
    smart_meter: string;
    additional_notes: string;
  };
  lighting: {
    led_bulbs: string;
    compact_fluorescent_bulbs: string;
    fluorescent_bulbs: string;
    halogen_bulbs: string;
    incandescent_bulbs: string;
    other: number;
    other_details: string;
  };
  cooking: {
    electric_hob: string;
    gas_hob: string;
    electric_oven: string;
    gas_oven: string;
    microwave: string;
    other: number;
    other_details: string;
  };
  appliances: {
    dishwasher: string;
    tumble_dryer: string;
    washing_machine: string;
  };
}

const smart_meter_options = [
  {
    label: i18next.t('Yes'),
    value: 'true',
  },
  {
    label: i18next.t('No'),
    value: 'false',
  },
];

const AppliancesAndLighting: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/appliances-and-lighting/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/appliances-and-lighting/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);
        if (hasSucceeded) {
          mutate();
          navigate(`/energy-bills?survey_id=${currentSurveyID?.survey_id}`);
        }
      },
      [navigate, submitForm]
    );
  }

  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>{i18next.t('6. Appliances and lighting')}</div>
          </h1>
          <Formik<Values>
            enableReinitialize
            initialValues={{
              ...data,
              lighting: {
                led_bulbs: data.lighting.led_bulbs,
                compact_fluorescent_bulbs:
                  data.lighting.compact_fluorescent_bulbs,
                fluorescent_bulbs: data.lighting.fluorescent_bulbs,
                halogen_bulbs: data.lighting.halogen_bulbs,
                incandescent_bulbs: data.lighting.incandescent_bulbs,
                other: data.lighting.other || 0,
                other_details: data.lighting.other_details,
              },
              cooking: {
                electric_hob: data.cooking.electric_hob,
                gas_hob: data.cooking.gas_hob,
                electric_oven: data.cooking.electric_oven,
                gas_oven: data.cooking.gas_oven,
                microwave: data.cooking.microwave,
                other: data.cooking.other || 0,
                other_details: data.cooking.other_details,
              },
            }}
            onSubmit={onSubmit}
          >
            {({ status, values, handleChange, setFieldValue, errors }) => (
              <Form className="space-y-8 ">
                <div>
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('6.1. Lighting')}
                    <span className="pl-1" data-tooltip-id={'section6part1'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section6part1'}
                        variant="info"
                        openOnClick
                      >
                        <Section6part1 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Enter number of bulbs in the home. If unknown or not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="lighting.led_bulbs"
                    label={i18next.t('LED bulb')}
                    errors={errors.lighting?.led_bulbs}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="lighting.compact_fluorescent_bulbs"
                    label={i18next.t('Compact fluorescent bulb')}
                    errors={errors.lighting?.compact_fluorescent_bulbs}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="lighting.fluorescent_bulbs"
                    label={i18next.t('Fluorescent bulb')}
                    errors={errors.lighting?.fluorescent_bulbs}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="lighting.halogen_bulbs"
                    label={i18next.t('Halogen bulb')}
                    errors={errors.lighting?.halogen_bulbs}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="lighting.incandescent_bulbs"
                    label={i18next.t('Incandescent bulb')}
                    errors={errors.lighting?.incandescent_bulbs}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="lighting.other"
                    label={i18next.t('Other')}
                    errors={errors.lighting?.other}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="lighting.other_details"
                    label={i18next.t('Other details')}
                    errors={errors.lighting?.other_details}
                    placeholder={i18next.t('Specify other')}
                    defaultValue={''}
                    disabled={values.lighting.other < 1}
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('6.2. Cooking')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Enter number of times used per week. If unknown or not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="cooking.electric_hob"
                    label={i18next.t('Electric hob')}
                    errors={errors.cooking?.electric_hob}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="cooking.gas_hob"
                    label={i18next.t('Gas hob')}
                    errors={errors.cooking?.gas_hob}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="cooking.electric_oven"
                    label={i18next.t('Electric oven')}
                    errors={errors.cooking?.electric_oven}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="cooking.gas_oven"
                    label={i18next.t('Gas oven')}
                    errors={errors.cooking?.gas_oven}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="cooking.microwave"
                    label={i18next.t('Microwave')}
                    errors={errors.cooking?.microwave}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="cooking.other"
                    label={i18next.t('Other')}
                    errors={errors.cooking?.other}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="cooking.other_details"
                    label={i18next.t('Other details')}
                    errors={errors.cooking?.other_details}
                    placeholder={i18next.t('Specify other')}
                    defaultValue={''}
                    disabled={values.cooking.other < 1}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('6.3. Appliances')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Enter number of times used per week. If unknown or not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="appliances.dishwasher"
                    label={i18next.t('Dishwasher')}
                    errors={errors.appliances?.dishwasher}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="appliances.tumble_dryer"
                    label={i18next.t('Tumble dryer')}
                    errors={errors.appliances?.tumble_dryer}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="appliances.washing_machine"
                    label={i18next.t('Washing machine')}
                    errors={errors.appliances?.washing_machine}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-6">
                    {i18next.t('6.4. Smart meter')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="smart_meter"
                    options={smart_meter_options}
                    onChange={(value) =>
                      setFieldValue(
                        'appliances_and_lighting.smart_meter',
                        value
                      )
                    }
                    errors={errors.appliances_and_lighting?.smart_meter}
                    defaultChecked={
                      values &&
                      values.appliances_and_lighting.smart_meter.toString()
                    }
                  />
                  <FileUpload
                    instructions={i18next.t(
                      'Add photos of light fittings, electric appliances and cooking appliances'
                    )}
                    fieldName={'appliances'}
                    clientId={currentSurveyID?.survey_id || ''}
                    section="APPLIANCES_AND_LIGHTING"
                  />
                  <TextArea
                    id="appliances_and_lighting.additional_notes"
                    label={i18next.t('Additional notes')}
                    name="appliances_and_lighting.additional_notes"
                    onChange={handleChange}
                    defaultValue={
                      values && values.appliances_and_lighting.additional_notes
                    }
                    placeholder={i18next.t('Add notes')}
                  />
                </div>
                <NavigationProgress
                  status={status}
                  path={`/space-heating/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={60}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AppliancesAndLighting;
