/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import Radio from '../../framework-components/select/radio';
import { Option } from '../../framework-components/select/types';
import TextArea from '../../framework-components/textarea';
import Loading from '../../framework-components/loading';
import FileUpload from '../../framework-components/file-upload';
import {
  Section2part1,
  Section2part2,
  Section2part3,
  TOOLTIP_STYLE,
} from './guidance';
import InfoIcon from '../assets/InfoIcon';
interface Values {
  front_facade_orientation: string;
  urban_context: string;
  exposure: string;
  additional_notes: string;
}

const front_facade_orientation_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('North'),
    value: 'NORTH',
  },
  {
    label: i18next.t('North East'),
    value: 'NORTH_EAST',
  },
  {
    label: i18next.t('East'),
    value: 'EAST',
  },
  {
    label: i18next.t('South'),
    value: 'SOUTH',
  },
  {
    label: i18next.t('South East'),
    value: 'SOUTH_EAST',
  },
  {
    label: i18next.t('South West'),
    value: 'SOUTH_WEST',
  },
  {
    label: i18next.t('West'),
    value: 'WEST',
  },
  {
    label: i18next.t('North West'),
    value: 'NORTH_WEST',
  },
];

const urban_context_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Urban'),
    value: 'URBAN',
  },
  {
    label: i18next.t('Sub-urban'),
    value: 'SUBURBAN',
  },
  {
    label: i18next.t('Rural'),
    value: 'RURAL',
  },
];

const exposure_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Open'),
    value: 'OPEN',
  },
  {
    label: i18next.t('Normal'),
    value: 'NORMAL',
  },
  {
    label: i18next.t('Dense/Enclosed'),
    value: 'DENSE_ENCLOSED',
  },
];

const BuildingLocation: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/building_location/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/building_location/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);

        if (hasSucceeded) {
          mutate();
          navigate(
            `/building-construction?survey_id=${currentSurveyID?.survey_id}`
          );
        }
      },
      [navigate, submitForm]
    );
  }
  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      <>
        <h1 className="text-2xl font-semibold text-gray-900">
          <div>{i18next.t('2. Building location')}</div>
        </h1>
        {data ? (
          <Formik<Values>
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}
          >
            {({ status, errors, setFieldValue, values, handleChange }) => (
              <Form className="space-y-8 ">
                <div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('2.1. Front facade orientation')}
                    <span className="pl-1" data-tooltip-id={'section2part1'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section2part1'}
                        variant="info"
                        openOnClick
                      >
                        <Section2part1 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="front_facade_orientation"
                    options={front_facade_orientation_options}
                    onChange={(value) =>
                      setFieldValue('front_facade_orientation', value)
                    }
                    errors={errors.front_facade_orientation}
                    defaultChecked={values && values.front_facade_orientation}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-6">
                    {i18next.t('2.2.  Urban context')}
                    <span className="pl-1" data-tooltip-id={'section2part2'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section2part2'}
                        variant="info"
                        openOnClick
                      >
                        <Section2part2 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="urban_context"
                    options={urban_context_options}
                    onChange={(value) => setFieldValue('urban_context', value)}
                    errors={errors.urban_context}
                    defaultChecked={values && values.urban_context}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-6">
                    {i18next.t('2.3. Exposure')}
                    <span className="pl-1" data-tooltip-id={'section2part3'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section2part3'}
                        variant="info"
                        openOnClick
                      >
                        <Section2part3 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="exposure"
                    options={exposure_options}
                    onChange={(value) => setFieldValue('exposure', value)}
                    errors={errors.exposure}
                    defaultChecked={values && values.exposure}
                  />

                  <TextArea
                    id="additional_notes"
                    label={i18next.t('Additional notes')}
                    instructions={i18next.t(
                      'For example complex roof layouts, access issues due to location for deliveries, etc.'
                    )}
                    name="additional_notes"
                    onChange={handleChange}
                    defaultValue={(values && values.additional_notes) || ''}
                    placeholder={i18next.t('Add notes')}
                  />

                  <FileUpload
                    instructions={i18next.t(
                      'Add photos of house number, front façade, house and its surroundings etc'
                    )}
                    fieldName={'location'}
                    clientId={currentSurveyID?.survey_id || ''}
                    section="BUILDING_LOCATION"
                  />
                </div>

                <NavigationProgress
                  status={status}
                  path={`/surveyor-details/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={20}
                />
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </>
    </div>
  );
};

export default BuildingLocation;
