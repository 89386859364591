import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as i18next from 'i18next';

import { useAPI } from '../../api/api';
import { DropdownOption } from '../../framework-components/dropdown/types';
import LocalDropdown from '../../framework-components/dropdown/local-dropdown';
import { SurveyContext } from '../../context-providers/survey-context';

const ViewExisting: React.FC = () => {
  const { setSurveyID } = useContext(SurveyContext);

  const navigate = useNavigate();
  const { data, isValidating } = useAPI(`/api/survey/lookup/`);
  const toObj = data
    ? Object.entries(data).map(([value, label]) => ({ value, label }))
    : null;
  const onSelect = useCallback(
    (item: DropdownOption) => {
      setSurveyID(item.value);
      navigate(`/assessment-overview/?survey_id=${item.value}`);
    },
    [navigate, setSurveyID]
  );

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">
        {i18next.t('Edit/view existing PRESS Survey')}
      </h1>
      <div />
      {toObj && !isValidating && (
        <em>
          <LocalDropdown
            options={toObj as DropdownOption[]}
            onSelect={onSelect}
            label={i18next.t('Search for address')}
            placeholder={i18next.t('Enter postcode')}
          />
        </em>
      )}
    </div>
  );
};

export default ViewExisting;
