import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';

import { useSubmitFormToAPI } from '../../api/forms';
import WrappedField from '../forms/wrapped-field';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import Loading from '../../framework-components/loading';

interface Values {
  name: string;
  role: string;
  company: string;
}

const SurveyorDetails: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI(
    `/api/survey/${currentSurveyID?.survey_id}/surveyor/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/surveyor/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);

        if (hasSucceeded) {
          mutate();
          navigate(
            `/building-location?survey_id=${currentSurveyID?.survey_id}`
          );
        }
      },
      [navigate, submitForm]
    );
  }

  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>{i18next.t('1. Details')}</div>
          </h1>
          <Formik<Values>
            enableReinitialize
            initialValues={data as Values}
            onSubmit={onSubmit}
          >
            {({ status, errors, values }) => (
              <Form className="space-y-8 ">
                <div className="space-y-6">
                  <WrappedField
                    name="name"
                    label={i18next.t('Surveyor Name')}
                    errors={errors.name}
                    placeholder={i18next.t('Enter surveyor name')}
                    defaultValue={values && values.name}
                  />
                  <WrappedField
                    name="role"
                    label={i18next.t('Role')}
                    errors={errors.role}
                    placeholder={i18next.t('Enter role')}
                    defaultValue={values && values.role}
                  />
                  <WrappedField
                    name="company"
                    label={i18next.t('Organisation name')}
                    errors={errors.company}
                    placeholder={i18next.t('Enter organisation name')}
                    defaultValue={values && values.company}
                  />
                </div>
                <NavigationProgress
                  status={status}
                  path={`/assessment-overview/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={10}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default SurveyorDetails;
