/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import TextArea from '../../framework-components/textarea';
import Radio from '../../framework-components/select/radio';
import { Option } from '../../framework-components/select/types';
import Loading from '../../framework-components/loading';
import WrappedField from '../forms/wrapped-field';
import Checkbox from '../../framework-components/select/checkbox';
import FileUpload from '../../framework-components/file-upload';
import { Section5part22, TOOLTIP_STYLE } from './guidance';
import InfoIcon from '../assets/InfoIcon';

interface Values {
  space_heating: {
    primary_energy_source: string;
    primary_energy_source_other_details: string;
    boiler_type: string;
    heating_delivery: string;
    secondary_energy_source: string;
    secondary_energy_source_other_details: string;
    additional_notes: string;
  };
  space_heating_type: {
    fireplace: number;
    stove: number;
    electric_heater: number;
    oil_filled_radiators: number;
    other: number;
    other_details: string;
  };
  space_heating_frequency_of_use: {
    fireplace: number;
    stove: number;
    electric_heater: number;
    oil_filled_radiators: number;
    other: number;
    other_details: string;
  };
  water_demand_source: {
    bath: number;
    shower: number;
    hand_washing_clothes: number;
    hand_washing_dishes: number;
    washing_machine: number;
    dishwasher: number;
  };
  water_heating_type: {
    boiler: boolean;
    electric_shower: boolean;
    hot_water_tank: boolean;
    hot_water_tank_capacity: number;
    immersion_heater: boolean;
    other: boolean;
    other_details: string;
  };
  heating_settings: {
    heating_month_start: string;
    heating_month_end: string;
    heating_times_winter_start: string;
    heating_times_winter_end: string;
    heating_times_summer_start: string;
    heating_times_summer_end: string;
    heating_temperature_winter: number;
    heating_temperature_summer: number;
  };
}

const primary_energy_source_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Gas'),
    value: 'GAS',
  },
  {
    label: i18next.t('Electric'),
    value: 'ELECTRIC',
  },
  {
    label: i18next.t('Oil'),
    value: 'OIL',
  },
  {
    label: i18next.t('Coal'),
    value: 'COAL',
  },
  {
    label: i18next.t('Biomass'),
    value: 'BIOMASS',
  },
  {
    label: i18next.t('Other - Specify'),
    value: 'OTHER',
  },
];

const boiler_type_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Conventional boiler'),
    value: 'CONVENTIONAL_BOILER',
  },
  {
    label: i18next.t('System boiler (with DHW tank)'),
    value: 'SYSTEM_BOILER',
  },
  {
    label: i18next.t('Combi boiler (instant DHW)'),
    value: 'COMBI_BOILER',
  },
  {
    label: i18next.t('Air source heat pump'),
    value: 'AIR_SOURCE_HEAT_PUMP',
  },
  {
    label: i18next.t('Ground source heat pump'),
    value: 'GROUND_SOURCE_HEAT_PUMP',
  },
  {
    label: i18next.t('Biomass boiler'),
    value: 'BIOMASS_BOILER',
  },
  {
    label: i18next.t('Storage heaters'),
    value: 'STORAGE_HEATERS',
  },
];

const heating_delivery_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Radiators'),
    value: 'RADIATORS',
  },
  {
    label: i18next.t('Underfloor Heating'),
    value: 'UNDERFLOOR_HEATING',
  },
  {
    label: i18next.t('Air Source'),
    value: 'AIR_SOURCE',
  },
];

const water_heating_type_options = [
  {
    label: i18next.t('Boiler'),
    value: 'water_heating_type.boiler',
  },
  {
    label: i18next.t('Electric shower'),
    value: 'water_heating_type.electric_shower',
  },
  {
    label: i18next.t('Hot water tank'),
    value: 'water_heating_type.hot_water_tank',
  },
  {
    label: i18next.t('Immersion heater'),
    value: 'water_heating_type.immersion_heater',
  },
  {
    label: i18next.t('Other'),
    value: 'water_heating_type.other',
  },
];

const SpaceHeating: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/space-heating/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/space-heating/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);
        if (hasSucceeded) {
          mutate();
          navigate(
            `/appliances-and-lighting?survey_id=${currentSurveyID?.survey_id}`
          );
        }
      },
      [navigate, submitForm]
    );
  }
  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>{i18next.t('5. Space heating')}</div>
          </h1>
          <Formik<Values>
            enableReinitialize
            initialValues={{
              ...data,
              space_heating_frequency_of_use: {
                fireplace: data.space_heating_frequency_of_use.fireplace,
                stove: data.space_heating_frequency_of_use.stove,
                electric_heater:
                  data.space_heating_frequency_of_use.electric_heater,
                oil_filled_radiators:
                  data.space_heating_frequency_of_use.oil_filled_radiators,
                other: data.space_heating_frequency_of_use.other || 0,
                other_details:
                  data.space_heating_frequency_of_use.other_details,
              },
              space_heating_type: {
                fireplace: data.space_heating_type.fireplace,
                stove: data.space_heating_type.stove,
                electric_heater: data.space_heating_type.electric_heater,
                oil_filled_radiators:
                  data.space_heating_type.oil_filled_radiators,
                other: data.space_heating_type.other || 0,
                other_details: data.space_heating_type.other_details,
              },
              water_heating_type: {
                boiler: Boolean(data.water_heating_type.boiler),
                electric_shower: Boolean(
                  data.water_heating_type.electric_shower
                ),
                hot_water_tank: Boolean(data.water_heating_type.hot_water_tank),
                immersion_heater: Boolean(
                  data.water_heating_type.immersion_heater
                ),
                hot_water_tank_capacity: Number(
                  data.water_heating_type.hot_water_tank_capacity
                ),
                other: Boolean(data.water_heating_type.other) || false,
                other_details: String(data.water_heating_type.other_details),
              },
            }}
            onSubmit={onSubmit}
          >
            {({ status, values, handleChange, setFieldValue, errors }) => (
              <Form className="space-y-8 ">
                <div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('5.1. Space heating - Primary')}
                  </div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('5.1.1. Energy source')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="space_heating.primary_energy_source"
                    options={primary_energy_source_options}
                    onChange={(value) =>
                      setFieldValue(
                        'space_heating.primary_energy_source',
                        value
                      )
                    }
                    errors={errors.space_heating?.primary_energy_source}
                    defaultChecked={
                      values && values.space_heating.primary_energy_source
                    }
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating.primary_energy_source_other_details"
                    label={i18next.t('Other details')}
                    errors={
                      errors.space_heating?.primary_energy_source_other_details
                    }
                    placeholder={i18next.t('Specify other')}
                    defaultValue={''}
                    disabled={
                      values.space_heating.primary_energy_source !== 'OTHER'
                    }
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.1.2. Boiler type')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="space_heating.boiler_type"
                    options={boiler_type_options}
                    onChange={(value) =>
                      setFieldValue('space_heating.boiler_type', value)
                    }
                    errors={errors.space_heating?.boiler_type}
                    defaultChecked={values && values.space_heating.boiler_type}
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.1.3. Heating delivery')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="space_heating.heating_delivery"
                    options={heating_delivery_options}
                    onChange={(value) =>
                      setFieldValue('space_heating.heating_delivery', value)
                    }
                    errors={errors.space_heating?.heating_delivery}
                    defaultChecked={
                      values && values.space_heating.heating_delivery
                    }
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.2. Space heating - Secondary')}
                  </div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('5.2.1. Energy source')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select one')}</em>
                  </div>
                  <Radio
                    name="space_heating.secondary_energy_source"
                    options={primary_energy_source_options}
                    onChange={(value) =>
                      setFieldValue(
                        'space_heating.secondary_energy_source',
                        value
                      )
                    }
                    errors={errors.space_heating?.secondary_energy_source}
                    defaultChecked={
                      values && values.space_heating.secondary_energy_source
                    }
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating.secondary_energy_source_other_details"
                    label={i18next.t('Other details')}
                    errors={
                      errors.space_heating
                        ?.secondary_energy_source_other_details
                    }
                    placeholder={i18next.t('Specify other')}
                    defaultValue={''}
                    disabled={
                      values.space_heating.secondary_energy_source !== 'OTHER'
                    }
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.2.2. Heating type')}
                    <span className="pl-1" data-tooltip-id={'section5part22'}>
                      <InfoIcon />
                      <ReactTooltip
                        style={TOOLTIP_STYLE as React.CSSProperties}
                        id={'section5part22'}
                        variant="info"
                        openOnClick
                      >
                        <Section5part22 />
                      </ReactTooltip>
                    </span>
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Insert the number of each and mark them on your floor plan drawing. If not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="space_heating_type.fireplace"
                    label={i18next.t('Fireplace')}
                    errors={errors.space_heating_type?.fireplace}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_type.stove"
                    label={i18next.t('Stove')}
                    errors={errors.space_heating_type?.stove}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_type.electric_heater"
                    label={i18next.t('Electric heater')}
                    errors={errors.space_heating_type?.electric_heater}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_type.oil_filled_radiators"
                    label={i18next.t('Oil filled radiator/s')}
                    errors={errors.space_heating_type?.oil_filled_radiators}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_type.other"
                    label={i18next.t('Other - Specify')}
                    errors={errors.space_heating_type?.other}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="space_heating_type.other_details"
                    label={i18next.t('Other details')}
                    errors={errors.space_heating_type?.other_details}
                    placeholder={i18next.t('Enter other details')}
                    defaultValue={''}
                    disabled={values.space_heating_type.other < 1}
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.2.3. Frequency of use')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Enter number of times they are used per week. If unknown or not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="space_heating_frequency_of_use.fireplace"
                    label={i18next.t('Fireplace')}
                    errors={errors.space_heating_frequency_of_use?.fireplace}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_frequency_of_use.stove"
                    label={i18next.t('Stove')}
                    errors={errors.space_heating_frequency_of_use?.stove}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_frequency_of_use.electric_heater"
                    label={i18next.t('Electric heater')}
                    errors={
                      errors.space_heating_frequency_of_use?.electric_heater
                    }
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_frequency_of_use.oil_filled_radiators"
                    label={i18next.t('Oil filled radiators')}
                    errors={
                      errors.space_heating_frequency_of_use
                        ?.oil_filled_radiators
                    }
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="space_heating_frequency_of_use.other"
                    label={i18next.t('Other - Specify')}
                    errors={errors.space_heating_frequency_of_use?.other}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="space_heating_frequency_of_use.other_details"
                    label={i18next.t('Other details')}
                    errors={
                      errors.space_heating_frequency_of_use?.other_details
                    }
                    placeholder={i18next.t('Enter other details')}
                    defaultValue={''}
                    disabled={values.space_heating_frequency_of_use.other < 1}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.3. Water heating')}
                  </div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('5.3.1. Demand source')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Enter number of times they are used per week. If unknown or not present enter 0.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2"
                    name="water_demand_source.bath"
                    label={i18next.t('Bath')}
                    errors={errors.water_demand_source?.bath}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="water_demand_source.shower"
                    label={i18next.t('Shower')}
                    errors={errors.water_demand_source?.shower}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="water_demand_source.hand_washing_clothes"
                    label={i18next.t('Hand washing clothes')}
                    errors={errors.water_demand_source?.hand_washing_clothes}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="water_demand_source.hand_washing_dishes"
                    label={i18next.t('Hand washing dishes')}
                    errors={errors.water_demand_source?.hand_washing_dishes}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2"
                    name="water_demand_source.washing_machine"
                    label={i18next.t('Washing machine')}
                    errors={errors.water_demand_source?.washing_machine}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="water_demand_source.dishwasher"
                    label={i18next.t('Dishwasher')}
                    errors={errors.water_demand_source?.dishwasher}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.3.2. Water heating type')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select all that apply')}</em>
                  </div>

                  <Checkbox
                    key={water_heating_type_options[0].label.toString()}
                    option={water_heating_type_options[0]}
                    checked={Boolean(values.water_heating_type.boiler)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={water_heating_type_options[1].label.toString()}
                    option={water_heating_type_options[1]}
                    checked={Boolean(values.water_heating_type.electric_shower)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={water_heating_type_options[2].label.toString()}
                    option={water_heating_type_options[2]}
                    checked={Boolean(values.water_heating_type.hot_water_tank)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />

                  <WrappedField
                    className="py-2"
                    name="water_heating_type.hot_water_tank_capacity"
                    label={i18next.t('Hot water tank capacity')}
                    errors={errors.water_heating_type?.hot_water_tank_capacity}
                    placeholder={i18next.t('Enter number')}
                    defaultValue={''}
                    disabled={
                      values.water_heating_type.hot_water_tank ? false : true
                    }
                  />
                  <Checkbox
                    key={water_heating_type_options[3].label.toString()}
                    option={water_heating_type_options[3]}
                    checked={Boolean(
                      values.water_heating_type.immersion_heater
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={water_heating_type_options[4].label.toString()}
                    option={water_heating_type_options[4]}
                    checked={Boolean(values.water_heating_type.other)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />

                  <WrappedField
                    className="py-2"
                    name="water_heating_type.other_details"
                    label={i18next.t('Other details')}
                    errors={errors.water_heating_type?.other_details}
                    placeholder={i18next.t('Enter other details')}
                    defaultValue={''}
                    disabled={values.water_heating_type.other ? false : true}
                  />

                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('5.4. Heating settings')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>
                      {i18next.t(
                        'Please enter details for occupied homes only. If unoccupied please leave blank.'
                      )}
                    </em>
                  </div>
                  <WrappedField
                    className="py-2 whitespace-pre whitespace-pre-wrap"
                    name="heating_settings.heating_month_start"
                    label={i18next.t('Heating months from:')}
                    placeholder={i18next.t('Enter month')}
                    errors={errors.heating_settings?.heating_month_start}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="heating_settings.heating_month_end"
                    label={i18next.t('to:')}
                    errors={errors.heating_settings?.heating_month_end}
                    placeholder={i18next.t('Enter month')}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2 whitespace-pre whitespace-pre-wrap"
                    name="heating_settings.heating_times_winter_start"
                    label={i18next.t('Heating times winter from:')}
                    placeholder={i18next.t('Enter time')}
                    errors={errors.heating_settings?.heating_times_winter_start}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="heating_settings.heating_times_winter_end"
                    label={i18next.t('to:')}
                    errors={errors.heating_settings?.heating_times_winter_end}
                    placeholder={i18next.t('Enter time')}
                    defaultValue={''}
                  />
                  <WrappedField
                    className="py-2 whitespace-pre whitespace-pre-wrap"
                    name="heating_settings.heating_times_summer_start"
                    label={i18next.t('Heating times summer from:')}
                    placeholder={i18next.t('Enter time')}
                    errors={errors.heating_settings?.heating_times_summer_start}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="heating_settings.heating_times_summer_end"
                    label={i18next.t('to:')}
                    errors={errors.heating_settings?.heating_times_summer_end}
                    placeholder={i18next.t('Enter time')}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="heating_settings.heating_temperature_winter"
                    label={i18next.t('Heating temperature winter')}
                    errors={errors.heating_settings?.heating_temperature_winter}
                    placeholder={i18next.t('Enter temperature')}
                    defaultValue={''}
                  />

                  <WrappedField
                    className="py-2"
                    name="heating_settings.heating_temperature_summer"
                    label={i18next.t('Heating temperature summer')}
                    errors={errors.heating_settings?.heating_temperature_summer}
                    placeholder={i18next.t('Enter temperature')}
                    defaultValue={''}
                  />

                  <TextArea
                    id="space_heating.additional_notes"
                    label={i18next.t('Additional notes')}
                    instructions={i18next.t(
                      'For example heating control type and location, TVRs, thermostats, timers, rooms where no space heating, etc.'
                    )}
                    name="space_heating.additional_notes"
                    onChange={handleChange}
                    defaultValue={
                      values && values.space_heating.additional_notes
                    }
                    placeholder={i18next.t('Add notes')}
                  />
                  <FileUpload
                    instructions={i18next.t(
                      'Add photos of heating systems showing brand and model details, radiators, air source valves, secondary heating elements, bath, shower and hot water tank'
                    )}
                    fieldName={'heating'}
                    clientId={currentSurveyID?.survey_id || ''}
                    section="SPACE_HEATING"
                  />
                </div>
                <NavigationProgress
                  status={status}
                  path={`/ventilation/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={50}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default SpaceHeating;
