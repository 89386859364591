/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as i18next from 'i18next';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import { Option } from '../../framework-components/select/types';
import Loading from '../../framework-components/loading';
import Checkbox from '../../framework-components/select/checkbox';
import FileUpload from '../../framework-components/file-upload';

interface Values {
  wall_thickness: boolean;
  position_and_size_of_windows_and_doors: boolean;
  position_and_size_of_loft_hatch: boolean;
  changes_to_layouts_and_extensions: boolean;
  position_of_boiler: boolean;
  position_and_size_of_radiators: boolean;
  position_secondary_heating: boolean;
  position_of_vents_and_fans: boolean;
  position_and_number_of_bulbs: boolean;
  position_and_types_of_meters: boolean;
  position_and_type_of_fuel_storage: boolean;
}

const options: ReadonlyArray<Option> = [
  {
    label: i18next.t('Wall thickness'),
    value: 'wall_thickness',
  },
  {
    label: i18next.t('Position and size of windows and doors'),
    value: 'position_and_size_of_windows_and_doors',
  },
  {
    label: i18next.t('Position and size of loft hatch'),
    value: 'position_and_size_of_loft_hatch',
  },
  {
    label: i18next.t('Changes to layouts and extensions'),
    value: 'changes_to_layouts_and_extensions',
  },
  {
    label: i18next.t('Position of boiler'),
    value: 'position_of_boiler',
  },
  {
    label: i18next.t('Position and size of radiators'),
    value: 'position_and_size_of_radiators',
  },
  {
    label: i18next.t('Position secondary heating'),
    value: 'position_secondary_heating',
  },
  {
    label: i18next.t('Position of vents and fans'),
    value: 'position_of_vents_and_fans',
  },
  {
    label: i18next.t('Position and number of bulbs'),
    value: 'position_and_number_of_bulbs',
  },
  {
    label: i18next.t('Position and types of meters'),
    value: 'position_and_types_of_meters',
  },
  {
    label: i18next.t('Position and type of fuel storage'),
    value: 'position_and_type_of_fuel_storage',
  },
];

const Floor: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/floor-plan/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/floor-plan/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);
        if (hasSucceeded) {
          mutate();
          navigate(
            `/assessment-overview?survey_id=${currentSurveyID?.survey_id}`
          );
        }
      },
      [navigate, submitForm]
    );
  }

  const onSubmit = useOnSubmit();
  return (
    <div className="space-y-6">
      {data ? (
        <>
          <h1 className="text-2xl font-semibold text-gray-900">
            <div>{i18next.t('10. Floor plan')}</div>
          </h1>
          <em>
            {i18next.t(
              'Draw and annotate a floor plan for each floor of the home, including relevant notes associated with the checklists below. Take a photo of your drawing and upload it below.'
            )}
          </em>
          <Formik<Values>
            enableReinitialize
            initialValues={{
              ...data,
              wall_thickness: Boolean(data.wall_thickness),
              position_and_size_of_windows_and_doors: Boolean(
                data.position_and_size_of_windows_and_doors
              ),
              position_and_size_of_loft_hatch: Boolean(
                data.position_and_size_of_loft_hatch
              ),
              changes_to_layouts_and_extensions: Boolean(
                data.changes_to_layouts_and_extensions
              ),
              position_of_boiler: Boolean(data.position_of_boiler),
              position_and_size_of_radiators: Boolean(
                data.position_and_size_of_radiators
              ),
              position_secondary_heating: Boolean(
                data.position_secondary_heating
              ),
              position_of_vents_and_fans: Boolean(
                data.position_of_vents_and_fans
              ),
              position_and_number_of_bulbs: Boolean(
                data.position_and_number_of_bulbs
              ),
              position_and_types_of_meters: Boolean(
                data.position_and_types_of_meters
              ),
              position_and_type_of_fuel_storage: Boolean(
                data.position_and_type_of_fuel_storage
              ),
            }}
            onSubmit={onSubmit}
          >
            {({ status, values, setFieldValue }) => (
              <Form className="space-y-8 ">
                <div>
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('10.1. Building elements')}
                  </div>
                  <Checkbox
                    key={options[0].label.toString()}
                    option={options[0]}
                    checked={Boolean(values.wall_thickness)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <Checkbox
                    key={options[1].label.toString()}
                    option={options[1]}
                    checked={Boolean(
                      values.position_and_size_of_windows_and_doors
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <Checkbox
                    key={options[2].label.toString()}
                    option={options[2]}
                    checked={Boolean(values.position_and_size_of_loft_hatch)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <Checkbox
                    key={options[3].label.toString()}
                    option={options[3]}
                    checked={Boolean(values.changes_to_layouts_and_extensions)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('10.2. Heating and ventilation system')}
                  </div>
                  <Checkbox
                    key={options[4].label.toString()}
                    option={options[4]}
                    checked={Boolean(values.position_of_boiler)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <Checkbox
                    key={options[5].label.toString()}
                    option={options[5]}
                    checked={Boolean(values.position_and_size_of_radiators)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <Checkbox
                    key={options[6].label.toString()}
                    option={options[6]}
                    checked={Boolean(values.position_secondary_heating)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <Checkbox
                    key={options[7].label.toString()}
                    option={options[7]}
                    checked={Boolean(values.position_of_vents_and_fans)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('10.3. Lightings')}
                  </div>
                  <Checkbox
                    key={options[8].label.toString()}
                    option={options[8]}
                    checked={Boolean(values.position_and_number_of_bulbs)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <div className="text-lg font-bold p-0 m-0 pt-3">
                    {i18next.t('10.4. Energy meters')}
                  </div>
                  <Checkbox
                    key={options[9].label.toString()}
                    option={options[9]}
                    checked={Boolean(values.position_and_types_of_meters)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                  <Checkbox
                    key={options[10].label.toString()}
                    option={options[10]}
                    checked={Boolean(values.position_and_type_of_fuel_storage)}
                    onChange={(field, value) => {
                      return setFieldValue(field, Boolean(value));
                    }}
                  />
                </div>
                <FileUpload
                  instructions={i18next.t('Add photo of floor plan')}
                  fieldName={'floor'}
                  clientId={currentSurveyID?.survey_id || ''}
                  section="FLOOR_PLAN"
                />
                <NavigationProgress
                  status={status}
                  path={`/residents/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={100}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Floor;
