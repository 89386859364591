/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Formik, Form, FormikHelpers, move } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import * as i18next from 'i18next';

import { useSubmitFormToAPI } from '../../api/forms';
import { SurveyContext } from '../../context-providers/survey-context';
import { useAPI } from '../../api/api';
import NavigationProgress from '../navigation-progress';
import TextArea from '../../framework-components/textarea';
import Loading from '../../framework-components/loading';
import { Option } from '../../framework-components/select/types';
import FileUpload from '../../framework-components/file-upload';
import Checkbox from '../../framework-components/select/checkbox';
import DragIcon from '../assets/DragIcon';

interface Values {
  possible_modifications_ranking: Array<string>;
  project_targets_ranking: Array<string>;
  available_data: {
    epc: boolean;
    energy_bills: boolean;
    architectural_drawings: boolean;
    building_fabric_specifications: boolean;
    systems_specifications: boolean;
    broadband_or_internet_availability: boolean;
  };
  potential_retrofit: { additional_notes: string };
}

const available_data_options: ReadonlyArray<Option> = [
  {
    label: i18next.t('EPC'),
    value: 'available_data.epc',
  },
  {
    label: i18next.t('Energy bills'),
    value: 'available_data.energy_bills',
  },
  {
    label: i18next.t('Architectural drawings'),
    value: 'available_data.architectural_drawings',
  },
  {
    label: i18next.t('Building fabric specifications'),
    value: 'available_data.building_fabric_specifications',
  },
  {
    label: i18next.t('Systems specifications'),
    value: 'available_data.systems_specifications',
  },
  {
    label: i18next.t('Broadband or internet availability'),
    value: 'available_data.broadband_or_internet_availability',
  },
];

const PotentialRetrofit: React.FC = () => {
  const { setSurveyID, currentSurveyID } = useContext(SurveyContext);
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get('survey_id') || undefined;
  if (!currentSurveyID) {
    setSurveyID(surveyId);
  }

  const { data, mutate } = useAPI<Values>(
    `/api/survey/${currentSurveyID?.survey_id}/possible-retrofit/get/`
  );

  function useOnSubmit(): (
    data: Values,
    formHelpers: Pick<
      FormikHelpers<Values>,
      'setSubmitting' | 'setErrors' | 'setStatus'
    >
  ) => void {
    const navigate = useNavigate();
    const submitForm = useSubmitFormToAPI<Values, null>(
      `/api/survey/${currentSurveyID?.survey_id}/possible-retrofit/set/`
    );

    return useCallback(
      async (submitData, formHelpers) => {
        const [hasSucceeded] = await submitForm(submitData, formHelpers);

        if (hasSucceeded) {
          mutate();
          navigate(`/residents?survey_id=${currentSurveyID?.survey_id}`);
        }
      },
      [navigate, submitForm]
    );
  }
  const onSubmit = useOnSubmit();
  const possible_modifications_ranking_options: Array<string> =
    data &&
    Object.entries(data).map(([k, v]) => ({
      id: k,
      value: v,
    }))[1].value;

  const project_targets_ranking_options: Array<string> =
    data &&
    Object.entries(data).map(([k, v]) => ({
      id: k,
      value: v,
    }))[2].value;

  const handleDrag = (result: DropResult) => {
    if (result?.destination) {
      if (result.destination.droppableId === 'possible_modifications_ranking') {
        const [reorderedItem] = possible_modifications_ranking_options.splice(
          result.source.index,
          1
        );
        possible_modifications_ranking_options.splice(
          result.destination.index,
          0,
          reorderedItem
        );
        move(
          possible_modifications_ranking_options,
          result.source.index,
          result.destination.index
        );
      }
      if (result.destination.droppableId === 'project_targets_ranking') {
        const [reorderedItem] = project_targets_ranking_options.splice(
          result.source.index,
          1
        );
        project_targets_ranking_options.splice(
          result.destination.index,
          0,
          reorderedItem
        );
        move(
          project_targets_ranking_options,
          result.source.index,
          result.destination.index
        );
      }
    }
  };

  return (
    <div className="space-y-6">
      <>
        <h1 className="text-2xl font-semibold text-gray-900">
          <div>{i18next.t('8. Potential retrofit')}</div>
        </h1>
        {data ? (
          <Formik<Values>
            enableReinitialize
            initialValues={{
              ...data,
              available_data: {
                epc: Boolean(data.available_data.epc),
                energy_bills: Boolean(data.available_data.energy_bills),
                architectural_drawings: Boolean(
                  data.available_data.architectural_drawings
                ),
                building_fabric_specifications: Boolean(
                  data.available_data.building_fabric_specifications
                ),
                systems_specifications: Boolean(
                  data.available_data.systems_specifications
                ),
                broadband_or_internet_availability: Boolean(
                  data.available_data.broadband_or_internet_availability
                ),
              },
            }}
            onSubmit={onSubmit}
          >
            {({ status, setFieldValue, values, handleChange }) => (
              <Form className="space-y-8 ">
                <div>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('8.1. Possible modifications required')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please rank in order of importance')}</em>
                  </div>

                  <DragDropContext onDragEnd={handleDrag}>
                    <ul>
                      <Droppable droppableId="possible_modifications_ranking">
                        {(provided) => (
                          <div
                            className="mb-6"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {possible_modifications_ranking_options.map(
                              (item: string, index: number) => {
                                return (
                                  <Draggable
                                    key={`mod[${index}]`}
                                    draggableId={`mod-${index}`}
                                    index={index}
                                  >
                                    {(drag) => (
                                      <li
                                        className="bg-gray-100 p-3 rounded border-2 my-2 focus:bg-blue-100 active:bg-blue-100 hover:bg-blue-100"
                                        key={index.toString()}
                                        ref={drag.innerRef}
                                        {...drag.draggableProps}
                                      >
                                        <input
                                          type="hidden"
                                          defaultValue={`${item.toString()}`}
                                        />
                                        <div {...drag.dragHandleProps}>
                                          <div className="flex justify-between">
                                            {i18next.t(item.toString())}
                                            <DragIcon />
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                );
                              }
                            )}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </ul>

                    <div className="text-lg font-bold p-0 m-0">
                      {i18next.t('8.2. Project targets')}
                    </div>
                    <div className="text-base font-normal p-0 m-0 pb-1">
                      <em>{i18next.t('Please rank in order of importance')}</em>
                    </div>
                    <ul>
                      <Droppable droppableId="project_targets_ranking">
                        {(provided) => (
                          <div
                            className="mb-6"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {project_targets_ranking_options.map(
                              (item: string, index: number) => {
                                return (
                                  <Draggable
                                    key={`target[${index}]`}
                                    draggableId={`target-${index}`}
                                    index={index}
                                  >
                                    {(drag) => (
                                      <li
                                        className="pointer-events-auto bg-gray-100 p-3 rounded border-2 my-2 focus:bg-blue-100 active:bg-blue-100 hover:bg-blue-100"
                                        key={index.toString()}
                                        ref={drag.innerRef}
                                        {...drag.draggableProps}
                                      >
                                        <input
                                          type="hidden"
                                          defaultValue={`${item.toString()}`}
                                        />
                                        <div {...drag.dragHandleProps}>
                                          <div className="flex justify-between">
                                            {i18next.t(item.toString())}
                                            <DragIcon />
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                );
                              }
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </ul>
                  </DragDropContext>
                  <div className="text-lg font-bold p-0 m-0">
                    {i18next.t('8.3. Available data')}
                  </div>
                  <div className="text-base font-normal p-0 m-0 pb-1">
                    <em>{i18next.t('Please select all that apply')}</em>
                  </div>
                  <Checkbox
                    key={available_data_options[0].label.toString()}
                    option={available_data_options[0]}
                    checked={Boolean(values.available_data.epc)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={available_data_options[1].label.toString()}
                    option={available_data_options[1]}
                    checked={Boolean(values.available_data.energy_bills)}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={available_data_options[2].label.toString()}
                    option={available_data_options[2]}
                    checked={Boolean(
                      values.available_data.architectural_drawings
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={available_data_options[3].label.toString()}
                    option={available_data_options[3]}
                    checked={Boolean(
                      values.available_data.building_fabric_specifications
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={available_data_options[4].label.toString()}
                    option={available_data_options[4]}
                    checked={Boolean(
                      values.available_data.systems_specifications
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />
                  <Checkbox
                    key={available_data_options[5].label.toString()}
                    option={available_data_options[5]}
                    checked={Boolean(
                      values.available_data.broadband_or_internet_availability
                    )}
                    onChange={(field, value) => {
                      return setFieldValue(field, value);
                    }}
                  />

                  <TextArea
                    id="additional_notes"
                    label={i18next.t('Additional notes')}
                    name="potential_retrofit.additional_notes"
                    onChange={handleChange}
                    defaultValue={
                      (values && values.potential_retrofit.additional_notes) ||
                      ''
                    }
                    placeholder={i18next.t('Add notes')}
                  />

                  <FileUpload
                    instructions={i18next.t(
                      'Add photos of outdoor spaces, damp/mould growth and quality of the house'
                    )}
                    fieldName={'location'}
                    clientId={currentSurveyID?.survey_id || ''}
                    section="POTENTIAL_RETROFIT"
                  />
                </div>

                <NavigationProgress
                  status={status}
                  path={`/energy-bills/?survey_id=${currentSurveyID?.survey_id}`}
                  progressPercentage={80}
                />
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </>
    </div>
  );
};
export default PotentialRetrofit;
